<template>
  <div class="bg">
    <v-img
        src="../assets/images/motif_batik.png"
        style="background-size: cover"
        gradient="to bottom right, white 10%, transparent 90%"
    >
      <v-form class="mt-4 mb-2">
        <v-card elevation="0"
                class="mx-auto"
                max-width="500"
                color="rgb(135,206,250, 0.3)"
        >
          <v-card-title class="white--text">
            <v-spacer/>
              REGISTRATION
            <v-spacer/>
          </v-card-title>
          <v-card-text>
            <v-text-field
                v-model="user.username"
                label="Username"
                hide-details="auto"
            ></v-text-field>
            <v-text-field
                v-model="user.email"
                label="Email"
                hide-details="auto"
            ></v-text-field>
            <v-text-field
                type="password"
                label="Password"
                v-model="user.password"
                hide-details="auto"
            ></v-text-field>
            <v-text-field
                v-model="user.fdivisionBean"
                label="Bidang (Internal ID)"
                hide-details="auto"
            ></v-text-field>
            <v-text-field
                v-model="user.organizationLevel"
                label="Level Organiasi"
                hide-details="auto"
            ></v-text-field>
            <v-autocomplete
                v-model="user.role"
                :items="itemsRoles"
                auto-select-first
                dense
                chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Role"
                persistent-hint
                single-line
            ></v-autocomplete>
            <v-alert
              dense
              text
              type="success"
              v-model="success"
            >
              create User <strong>{{user.username}}</strong> success
            </v-alert>
            <v-snackbar
                v-model="snackbar"
            >
              {{ message }}

              <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </v-card-text>
          <v-card-actions class="ma-4">
            <v-spacer/>
            <v-btn
                color="primary"
                @click="handleSignIn"
                width="200px"
            >Sign up</v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-spacer></v-spacer>

<!--      <v-footer-->
<!--          color="rgb(0,0,0,0)"-->
<!--          class="justify-center pl-0 footer_image" inset app-->
<!--      >-->
<!--      </v-footer>-->
    </v-img>
  </div>

</template>

<script>
import AuthService from '@/services/auth-service'
import User from '../models/user';
import ERole from "@/models/e-role";

export default {
  name: 'RegisterView',
  components: {},
  data() {
    return {
      user: new User('', ''),
      loading: false,
      snackbar: false,
      message: '',
      success: false,

      itemsRoles:[
          ERole.ROLE_ADMIN,
          ERole.ROLE_USER
      ],

    };
  },
  computed: {
    // loggedIn() {
    //   return this.$store.state.auth.status.loggedIn;
    // }

  },
  created() {
    // if (this.loggedIn) {
    //   this.$router.push('/profile');
    // }
  },
  methods: {
    handleSignIn() {
      this.success = false //diReset dulu
      // console.log(this.user)
      let requestUser = this.user
      requestUser.roles = [ this.user.role, 'ROLE_TEST']
      requestUser.organizationLevel = this.user.organizationLevel

      console.log(`cel level organisasi ${requestUser.organizationLevel} >> ${this.user.organizationLevel}`)

      AuthService.register(requestUser).then(
        response=>{
          console.log(response)
          // this.$router.push('/login')
          this.success = true
          this.message = `Create User ${this.user.username} sukses `
          this.snackbar = true

        },
        error=>{
          console.log(error)
          this.message = `Fail create ${this.user.username} sukses `
          this.message = `Fail create user `
          this.snackbar = true
        }
      )

    }
  }
};
</script>
<style scoped>
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /*background: url( 'https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg') no-repeat center center;*/
    background-size: cover;
    /*background-image: url( 'https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg'), linear-gradient(to bottom right, #f7d96a 10%, transparent 90%);*/
    background-image: linear-gradient(to bottom right, floralwhite 50%, transparent 90%);
    /*transform: scale(1.1);*/
  }
  /*background: url( 'https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg') no-repeat center center;*/
  /*background-color: lightyellow;*/
  /*background: url( 'https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg') no-repeat center center;*/

  .footer_image{
    background-image: url('https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg'), linear-gradient(#eb01a5, #d13531);
    background-color: rgba(0, 0, 0, 0.5);
  }

</style>


